import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import logo from './hughes_software_logo_white.png';
import icon from './HughesSoftwareIconWhite.png'; // Import the icon
import { Engine, Render, World, Bodies, Mouse, MouseConstraint, Events } from 'matter-js';

function BallComponent({ onWallHit }) {
  const sceneRef = useRef(null);
  const engineRef = useRef(Engine.create({
    // Improve collision detection by increasing the velocity threshold for sleeping and the position correction factor
    positionIterations: 6,
    velocityIterations: 4,
    constraintIterations: 2
  }));

  useEffect(() => {
    const engine = engineRef.current;
    const heroSection = document.querySelector('.App-hero');
    const heroSectionHeight = heroSection.clientHeight;
    const render = Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        wireframes: false,
        background: 'transparent',
        width: window.innerWidth,
        height: heroSectionHeight
      }
    });

    const ballDiameter = 60;
    const ball = Bodies.circle(window.innerWidth / 2, 200, ballDiameter / 2, {
      density: 0.04,
      frictionAir: 0.005,
      restitution: 0.9,
      render: {
        fillStyle: 'white',
        sprite: {
          texture: icon,
          xScale: ballDiameter / 200,
          yScale: ballDiameter / 200
        }
      }
    });

    const wallOptions = { isStatic: true, render: { visible: false } };
    const floor = Bodies.rectangle(window.innerWidth / 2, heroSectionHeight, window.innerWidth, 50, wallOptions);
    const leftWall = Bodies.rectangle(-100, 0, 200, heroSectionHeight * 100, wallOptions); // Increased thickness
    const rightWall = Bodies.rectangle(window.innerWidth + 100, 0, 200, heroSectionHeight * 100, wallOptions); // Increased thickness

    World.add(engine.world, [ball, floor, leftWall, rightWall]);

    Render.run(render);
    Engine.run(engine);

    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        render: { visible: false },
        stiffness: 0.2,
        angularStiffness: 0
      }
    });
    World.add(engine.world, mouseConstraint);

    // Adding event listener for collisions with walls
    Events.on(engine, 'collisionStart', event => {
      event.pairs.forEach(pair => {
        if (pair.bodyA === leftWall || pair.bodyA === rightWall || pair.bodyB === leftWall || pair.bodyB === rightWall) {
          onWallHit(); // Callback for changing the background color
        }
      });
    });

    const handleMouseLeave = () => {
      mouseConstraint.constraint.pointA = null;
      mouseConstraint.mouse.button = -1;
    };

    heroSection.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      Render.stop(render);
      World.clear(engine.world);
      Engine.clear(engine);
      render.canvas.remove();
      render.textures = {};
      heroSection.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []); // Removed dependency on external props

  return <div ref={sceneRef} />;
}

function App() {
  const [backgroundColor, setBackgroundColor] = useState('#000746');

  const changeBackgroundColor = () => {
    setBackgroundColor(prevColor => prevColor === '#000746' ? '#f49265' : '#000746');
  };

  return (
    <div className="App">
      <nav className="App-nav">
        <img src={logo} className="App-logo" alt="Hughes Software Solutions" />
      </nav>
      <main className="App-main">
        <section className="App-hero" style={{ backgroundColor }}>
          <BallComponent onWallHit={changeBackgroundColor} />
          <h1>Welcome</h1>
          <p>More coming soon</p>
        </section>
        <section className="App-services">
          <h2>Our Expertise</h2>
          <div className="service-list">
            <div className="service">
              <h3>Software Development</h3>
              <p>Desktop, web, and mobile application development and hosting.</p>
            </div>
            <div className="service">
              <h3>Drones</h3>
              <p>First-to-market drone payloads and native application development.</p>
            </div>
            <div className="service">
              <h3>IoT</h3>
              <p>Robust industrial IoT, analytics, remote access, and live visualization solutions.</p>
            </div>
            <div className="service">
              <h3>AI</h3>
              <p>Custom model training, integration, and collaboration.</p>
            </div>
          </div>
        </section>
        <footer className="App-footer">
          <p>Contact us: <a href="mailto:connor@hughessoftwaresolutions.com">connor@hughessoftwaresolutions.com</a></p>
        </footer>
      </main>
    </div>
  );
}

export default App;
